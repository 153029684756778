import React, { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { makeStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'react-moment';
import axios from "axios";
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
} from 'react-table'
import matchSorter from 'match-sorter'

import projectData from '../Table/approvalData'
import ProjectDetails from '../DataComponents/projectDetails';

import EditForm from '../Edit/EditForm.js';

const Styles = styled.div`
  padding: 1rem;

  .td_title {
    white-space: nowrap;
  }

  table {
    border-spacing: 0;
    border: 1px solid #D3D3D3;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #D3D3D3;
      border-right: 1px solid #D3D3D3;

      :last-child {
        border-right: 0;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0.25rem;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

thead > tr > th > div > span {
    color: #333;
}

.td_title {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.approvalControls {
    width: 250px;
}

.adminActions {
    height: 100%;
}

.adminControls {
    display: flex;
    width: 100%;
    border-bottom: 1px;
}

.editButton: { 
    display: inline-block;
    text-align: center;
    padding: 4px;
    margin: 4px;
}
.editButton:hover {
    background-color: lightGray;
    cursor: pointer;
}

.editIcon: {
    padding 4px;
}
`
// await axios.patch('http://demo0725191.mockable.io/patch_data', { new_name: 'My new cool name!' })



// Create an editable cell renderer
const EditableCell = ({
    cell: { value: initialValue },
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
    }

    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (!editable) {
        return `${initialValue}`
    }

    return <input value={value} onChange={onChange} onBlur={onBlur} />
}



function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, data, updateMyData, skipReset }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // And also our default editable cell
            Cell: EditableCell,
        }),
        []
    )

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            updateMyData,
            // We also need to pass this so the page doesn't change
            // when we edit the data.
            autoResetPage: !skipReset,
            autoResetSelectedRows: !skipReset,
            disableMultiSort: true,
        },
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    )

    // Render the UI for your table
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>
                                                {cell.isGrouped ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <React.Fragment>
                                                        <span {...row.getExpandedToggleProps()}>
                                                            {row.isExpanded ? '⬇' : '➡'}
                                                        </span>{' '}
                                                        {cell.render('Cell', { editable: false })} (
                                                        {row.subRows.length})
                                                    </React.Fragment>
                                                ) : cell.isAggregated ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    cell.render('Aggregated')
                                                ) : cell.isRepeatedValue ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    cell.render('Cell', { editable: false })
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
            <div className="text-center">
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </div>
            <div className="text-center">
                <button className="main" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button className="main" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button className="main" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button className="main" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}


            </div>

            <div className="text-center">
                <span>
                    Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    className={'showmaxrecords'}
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </React.Fragment>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number'


// XXX Break this out into a separate component file

function ApprovalAdmin() {
    const [showProjectDetails, setShowProjectDetails] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);
    const [idVal, setIdValue] = React.useState(false);
    const [titleVal, setTitleValue] = React.useState(false);
    const [typeVal, setTypeValue] = React.useState(false);
    const [mentorNameVal, setMentorNameValue] = React.useState(false);
    const [mentorEmailVal, setMentorEmailValue] = React.useState(false);
    const [statusVal, setStatusValue] = React.useState(false);
    const [deptVal, setDeptValue] = React.useState(false);
    const [locationVal, setLocationValue] = React.useState(false);
    const [descVal, setDescValue] = React.useState(false);
    const [postDateVal, setPostDateValue] = React.useState(false);
    const [expiryDateVal, setExpiryDateValue] = React.useState(false);
    const [typeIdVal, setTypeIdValue] = React.useState(false);
    const [deptIdVal, setDeptIdValue] = React.useState(false);
    const [locationIdVal, setLocationIdValue] = React.useState(false);
    const [urlVal, setUrlValue] = React.useState(false);
    const [phoneVal, setPhoneValue] = React.useState(false);
    const [mentorIdVal, setMentorIdValue] = React.useState(false);
    const [adminContactVal, setAdminContactValue] = React.useState(false);
    const [isApprovedVal, setIsApprovedValue] = React.useState(false);
    const [isEqualOppVal, setIsEqualOppValue] = React.useState(false);
    const [isRejectedVal, setIsRejectedValue] = React.useState(false);

    const hideProjectDetails = () => setShowProjectDetails(false);

    const hideEditForm = () => {
        setShowEditForm(false);
        fetchAndUpdate();
    }

    const showDetails = (id, title, type, mentorName, mentorEmail, adminContact, department, location,
        description, status, postDate, expiryDate) => {
        setShowProjectDetails(true);
        setIdValue(id);
        setTitleValue(title);
        setTypeValue(type);
        setMentorNameValue(mentorName);
        setMentorEmailValue(mentorEmail);
        setAdminContactValue(adminContact);
        setDeptValue(department);
        setLocationValue(location);
        setDescValue(description);
        setStatusValue(status);
        setPostDateValue(postDate);
        setExpiryDateValue(expiryDate);
    }

    const showEdit = (id, title, projectTypeName, projectTypeId, deptId, locationId, mentorName,
        mentorEmail, adminContact, departmentName, locationName, description, status, postDate, expiryDate,
        url, mentorPhone, mentorId, isApproved, isEqualOpp, isRejected
    ) => {
        setShowEditForm(true);
        setIdValue(id);
        setTitleValue(title);
        setTypeValue(projectTypeName);
        setTypeIdValue(projectTypeId);
        setDeptIdValue(deptId);
        setLocationIdValue(locationId);
        setMentorNameValue(mentorName);
        setMentorEmailValue(mentorEmail);
        setAdminContactValue(adminContact);
        setDeptValue(departmentName);
        setLocationValue(locationName);
        setDescValue(description);
        setStatusValue(status);
        setPostDateValue(postDate);
        setExpiryDateValue(expiryDate);
        setUrlValue(url);
        setPhoneValue(mentorPhone);
        setMentorIdValue(mentorId);
        setIsApprovedValue(isApproved);
        setIsEqualOppValue(isEqualOpp);
        setIsRejectedValue(isRejected);
    }


    const updateProject = async (project, method) => {
        if (method === "approve") {
            project.isApproved = 1;
            project.isRejected = 0;
        } else {
            project.isApproved = 0;
            project.isRejected = 1;
        }
        await axios({
            method: "PUT",
            url: "api/projects/" + project.id,
            data: JSON.stringify(project),
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });
    };

    const handleApprove = async (e) => {
        await updateProject(e, "approve");
        fetchAndUpdate(); // fetch data and update state
    }

    const handleReject = async (e) => {
        await updateProject(e, "reject");
        fetchAndUpdate(); // fetch data and update state
    }

    const handleEdit = async (project) => {
        return <EditForm />;
    }

    const HideNull = (value) => {
        if (value === undefined || value == null) {
            return 'Not Available';
        } else {
            return value;
        }
    }

    const columns = React.useMemo(
        () => [

            {
                Header: 'Approve Research Opportunities',
                columns: [
                    // id, title, projectTypeName, projectTypeId, deptId, locationId, mentorName,
                    // mentorEmail, departmentName, locationName, description, status, postDate, expiryDate,
                    // url, mentorPhone, mentorId, isApproved, isEqualOpp, isRejected
                    
                    {
                        Header: 'Approve/Reject',
                        className: "approvalControls",
                        Cell: ({ row }) =>
                            <div><Button variant="success" onClick={() => handleApprove(row.original)}>Approve</Button>
                                <Button variant="danger" onClick={() => window.confirm("Are you sure you wish to reject this item?") && handleReject(row.original)}>Reject</Button></div>
                    },
                    {
                        Header: 'Eligible Trainee(s)',
                        accessor: 'projectTraineeNames',
                        Cell: ({ row }) => <div>{row.original.projectTraineeNames.join(', ')}</div>,
                    },
                    {
                        Header: 'Type',
                        accessor: 'projectTypeName',
                    },

                    //(row.original.gamerTag == 'undefined' || row.original.gamerTag == '' || row.original.gamerTag == null) ? 'Dungeon Master' : row.original.gamerTag
                    {
                        Header: 'Project/Program Title',
                        accessor: 'title',
                        className: "approval",
                        Cell: ({ row }) =>
                            <a href="#" onClick={() =>
                                showDetails(
                                    row.original.id,
                                    row.original.title,
                                    row.original.projectTypeName,
                                    row.original.mentorName,
                                    row.original.mentorEmail,
                                    row.original.adminContact,
                                    row.original.departmentName,
                                    (row.original.locationName == 'undefined' || row.original.locationName == '' || row.original.locationName == null) ? 'Not Available' : row.original.locationName,
                                    row.original.description,
                                    row.original.status,
                                    row.original.postDate,
                                    row.original.expiryDate
                                )}>
                                {row.original.title}
                            </a>
                    },
                    {
                        Header: 'Mentor Affiliation',
                        accessor: 'departmentName',
                    },
                    {
                        Header: 'Location',
                        accessor: 'locationName',
                        // Cell: ({ row }) => <HideNull>{row.original.locationName}</HideNull>
                    },
                    {
                        Header: 'Mentor',
                        accessor: 'mentorName',

                    },
                    {
                        Header: 'Start Date',
                        accessor: 'postDate',
                        Cell: ({ row }) => <Moment format="MM/DD/YYYY">{row.original.postDate}</Moment>
                    },
                    {
                        Header: 'End Date',
                        accessor: 'expiryDate',
                        Cell: ({ row }) => <Moment format="MM/DD/YYYY">{row.original.expiryDate}</Moment>
                    },
                    {
                        id: 'Edit',
                        className: "EditButton",
                        Cell: ({ row }) =>
                            <div title="Edit Project" className="adminControls">
                                <div className="editButton" title="Edit Project">
                                    <EditIcon sx={{ "& :hover": { color: "Blue" } }} className="editIcon" fontSize="medium" onClick={() =>
                                        showEdit(
                                            row.original.id,
                                            row.original.title,
                                            row.original.projectTypeName,
                                            row.original.projectTypeId,
                                            row.original.deptId,
                                            row.original.locationId,
                                            row.original.mentorName,
                                            row.original.mentorEmail,
                                            row.original.adminContact,
                                            row.original.departmentName,
                                            row.original.locationName,
                                            row.original.description,
                                            row.original.status,
                                            row.original.postDate,
                                            row.original.expiryDate,
                                            row.original.url,
                                            row.original.mentorPhone,
                                            row.original.mentorId,
                                            row.original.isApproved,
                                            row.original.isEqualOpp,
                                            row.original.isRejected
                                        )} />
                                    </div>
                                </div>
                    }
                ]
            }
        ],
        [handleApprove, handleReject]
    )

    const [data, setData] = React.useState([]);
    const [error, setError] = useState(null);

    const fetchAndUpdate = useCallback(() => {
        console.log('Fetching data...');
        projectData()
            .then(res => {
                console.log('Data received:', res.data);
                setData(res.data);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
                setError(err.message || 'An error occurred while fetching data');
            });
    }, []);

    React.useEffect(() => {
        console.log('Component mounted, fetching initial data...');
        fetchAndUpdate()
    }, [fetchAndUpdate]);

    //const [data, setData] = React.useState(() => pdata)
    const [originalData] = React.useState(data)

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.
    const skipResetRef = React.useRef(false)

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        skipResetRef.current = true
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...row,
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    React.useEffect(() => {
        skipResetRef.current = false
    }, [data])

    // Let's add a data resetter/randomizer to help
    // illustrate that flow...
    const resetData = () => {
        // Don't reset the page when we do this
        skipResetRef.current = true
        setData(originalData)
    }

    return (
        <div id="mainContent">
            <div id="researchApprovalTable">
                <Styles>

                    <br />
                    <div className="instructions">
                        Approve or Reject Research Opportunities
                    </div>
                    <br />
                    {/* <button onClick={resetData}>Reset Data</button> */}
                    <Table
                        columns={columns}
                        data={data}
                        updateMyData={updateMyData}
                        skipReset={skipResetRef.current}
                    />
                </Styles>
            </div>
            {showProjectDetails && (
                <ProjectDetails
                    idVal={idVal}
                    titleVal={titleVal}
                    typeVal={typeVal}
                    mentorNameVal={mentorNameVal}
                    mentorEmailVal={mentorEmailVal}
                    adminContactVal={adminContactVal}
                    deptVal={deptVal}
                    locationVal={locationVal}
                    statusVal={statusVal}
                    descVal={descVal}
                    postDateVal={<Moment format="MM/DD/YYYY">{postDateVal}</Moment>}
                    expiryDateVal={<Moment format="MM/DD/YYYY">{expiryDateVal}</Moment>}
                    hide={hideProjectDetails}
                />
            )}

            {showEditForm && (
                <EditForm
                    idVal={idVal}
                    titleVal={titleVal}
                    typeVal={typeVal}
                    typeIdVal={typeIdVal}
                    deptIdVal={deptIdVal}
                    locationIdVal={locationIdVal}
                    mentorNameVal={mentorNameVal}
                    mentorEmailVal={mentorEmailVal}
                    adminContactVal={adminContactVal}
                    deptVal={deptVal}
                    locationVal={locationVal}
                    statusVal={statusVal}
                    descVal={descVal}
                    postDateVal={postDateVal}
                    expiryDateVal={expiryDateVal}
                    urlVal={urlVal}
                    phoneVal={phoneVal}
                    mentorIdVal={mentorIdVal}
                    isApprovedVal={isApprovedVal}
                    isEqualOppVal={isEqualOppVal}
                    isRejectedVal={isRejectedVal}
                    updateAndHide={hideEditForm}
                />
            )}
        </div>

    )
}

export default ApprovalAdmin;
