import React, { useState, useCallback } from 'react';
import ReactDOM from "react-dom";
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from "@mui/material/Box";
import Moment from 'react-moment';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
} from 'react-table'
import matchSorter from 'match-sorter'

import projectData from '../Table/projectDataAdmin'

import ProjectDetails from '../DataComponents/projectDetails';

import EditForm from '../Edit/EditForm.js';

const Styles = styled.div`
  padding: 1rem;

  .td_title {
    white-space: nowrap;
  }



  table {
    border-spacing: 0;
    border: 1px solid #D3D3D3;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #D3D3D3;
      border-right: 1px solid #D3D3D3;

      :last-child {
        border-right: 0;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0.25rem;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

thead > tr > th > div > span {
    color: #333;
}

.td_title {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.activeColor {
    text-align: center;
    color: green;
    font-weight: bold;
}

.expiredColor {
    text-align: center;
    color: red;
    font-weight: bold;
}

.opportunityColor {
    text-align: center;
    color: #ffc40c;
    font-weight: bold;
}

.adminControls {
    display: flex;
    width: 100%;
    border-bottom: 1px;
}

.editButton {
    flex: 1;
}

.deleteButton: { 
    display: inline-block;
    text-align: center;
    padding: 4px;
}
.deleteButton:hover {
    background-color: lightGray;
    cursor: pointer;
}

.deleteIcon: {
    padding 4px;
}

.editButton: { 
    display: inline-block;
    text-align: center;
    padding: 4px;
    margin: 4px;
}
.editButton:hover {
    background-color: lightGray;
    cursor: pointer;
}

.editIcon: {
    padding 4px;
}

`

// Create an editable cell renderer
const EditableCell = ({
    cell: { value: initialValue },
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
    }

    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (!editable) {
        return `${initialValue}`
    }

    return <input value={value} onChange={onChange} onBlur={onBlur} />
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search...`}
        />
    )
}

function EmptyFilter() {
    return (<select style={{ visibility: 'hidden' }}></select>)
}

// Custom SelectColumnFilter
// XXX kind of a hack but it works.  I could call the API again or transmute the values from state, but this is easier in this case. :)
function TraineeSelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {

    const options = ["Medical Student", "Resident", "Fellow"];

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

function StatusSelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {

    const options = ["Open", "Potential", "Expired"];

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {

        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        // Array.from(new Set(["b","a","c"])).sort();
        // [...(new Set(["b","a","c"]))].sort(); // with spread.
        const a = Array.from(options).sort();
        const sorted = new Set(a);
        return [...sorted.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <React.Fragment>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </React.Fragment>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '10%',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '10%',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, data, updateMyData, skipReset }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
            // And also our default editable cell
            Cell: EditableCell,
        }),
        []
    )

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            groupBy,
            expanded,
            filters,
            selectedRowPaths,
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData,
            // We also need to pass this so the page doesn't change
            // when we edit the data. Undefined tells it to use the default
            autoResetPage: !skipReset,
            autoResetSelectedRows: !skipReset,
            disableMultiSort: true,
        },
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,

    )

    // Render the UI for your table
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        <div>

                                            <span {...column.getSortByToggleProps()}>
                                                {column.render('Header')}
                                                {/* Add a sort direction indicator */}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </div>
                                        {/* Render the columns filter UI */}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>
                                                {cell.isGrouped ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <React.Fragment>
                                                        <span {...row.getExpandedToggleProps()}>
                                                            {row.isExpanded ? '⬇' : '➡'}
                                                        </span>{' '}
                                                        {cell.render('Cell', { editable: false })} (
                                                        {row.subRows.length})
                                                    </React.Fragment>
                                                ) : cell.isAggregated ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    cell.render('Aggregated')
                                                ) : cell.isRepeatedValue ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    cell.render('Cell', { editable: false })
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
            <div className="text-center">
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </div>
            <div className="text-center">
                <button className="main" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button className="main" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button className="main" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button className="main" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button >{' '}


            </div>

            <div className="text-center">
                <span>
                    Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    className={'showmaxrecords'}
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </React.Fragment>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

// This is a custom aggregator that
// takes in an array of values and
// returns the rounded median
function roundedMedian(values) {
    let min = values[0] || ''
    let max = values[0] || ''

    values.forEach(value => {
        min = Math.min(min, value)
        max = Math.max(max, value)
    })

    return Math.round((min + max) / 2)
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </React.Fragment>
        )
    }
)
// XXX Break this out into a separate component file

const deleteProject = async (project) => {
    project.isDeleted = 1;
    await axios({
        method: "PUT",
        url: "api/projects/" + project.id,
        data: JSON.stringify(project),
        headers: { 'Content-Type': 'application/json; charset=utf-8' }
    });
};

function ResearchEntriesAdmin() {

    const [isShown, setIsShown] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);
    const [idVal, setIdValue] = React.useState(false);
    const [titleVal, setTitleValue] = React.useState(false);
    const [typeVal, setTypeValue] = React.useState(false);
    const [mentorNameVal, setMentorNameValue] = React.useState(false);
    const [mentorEmailVal, setMentorEmailValue] = React.useState(false);
    const [adminContactVal, setAdminContactValue] = React.useState(false);
    const [statusVal, setStatusValue] = React.useState(false);
    const [deptVal, setDeptValue] = React.useState(false);
    const [statusNameVal, setStatusNameValue] = React.useState(false);
    const [locationVal, setLocationValue] = React.useState(false);
    const [descVal, setDescValue] = React.useState(false);
    const [postDateVal, setPostDateValue] = React.useState(false);
    const [expiryDateVal, setExpiryDateValue] = React.useState(false);
    const [typeIdVal, setTypeIdValue] = React.useState(false);
    const [deptIdVal, setDeptIdValue] = React.useState(false);
    const [locationIdVal, setLocationIdValue] = React.useState(false);
    const [urlVal, setUrlValue] = React.useState(false);
    const [phoneVal, setPhoneValue] = React.useState(false);
    const [mentorIdVal, setMentorIdValue] = React.useState(false);
    const [isApprovedVal, setIsApprovedValue] = React.useState(false);
    const [isEqualOppVal, setIsEqualOppValue] = React.useState(false);
    const [isRejectedVal, setIsRejectedValue] = React.useState(false);

    const hide = () => {
        setIsShown(false);
    }

    const hideEditForm = () => {
        setShowEditForm(false);
        fetchAndUpdate();
    }

    const show = (id, title, type, mentorName, mentorEmail, adminContact, department, statusName, location, description, status, postDate, expiryDate) => {
        setIsShown(true);
        setIdValue(id);
        setTitleValue(title);
        setTypeValue(type);
        setMentorNameValue(mentorName);
        setMentorEmailValue(mentorEmail);
        setAdminContactValue(adminContact);
        setDeptValue(department);
        setStatusNameValue(statusName);
        setLocationValue(location);
        setDescValue(description);
        setStatusValue(status);
        setPostDateValue(postDate);
        setExpiryDateValue(expiryDate);
    }

    const showEdit = (id, title, projectTypeName, projectTypeId, deptId, locationId, mentorName,
        mentorEmail, adminContact, departmentName, locationName, description, status, postDate, expiryDate,
        url, mentorPhone, mentorId, isApproved, isEqualOpp, isRejected
    ) => {
        setShowEditForm(true);
        setIdValue(id);
        setTitleValue(title);
        setTypeValue(projectTypeName);
        setTypeIdValue(projectTypeId);
        setDeptIdValue(deptId);
        setLocationIdValue(locationId);
        setMentorNameValue(mentorName);
        setMentorEmailValue(mentorEmail);
        setAdminContactValue(adminContact);
        setDeptValue(departmentName);
        setLocationValue(locationName);
        setDescValue(description);
        setStatusValue(status);
        setPostDateValue(postDate);
        setExpiryDateValue(expiryDate);
        setUrlValue(url);
        setPhoneValue(mentorPhone);
        setMentorIdValue(mentorId);
        setIsApprovedValue(isApproved);
        setIsEqualOppValue(isEqualOpp);
        setIsRejectedValue(isRejected);
    }

    const HideNull = (value) => {
        if (value === undefined || value === null || !value.length) {
            return 'Not Available';
        }
    }
    const handleDelete = async (e) => {
        await deleteProject(e);
        fetchAndUpdate(); // fetch data and update state
    }


    const columns = React.useMemo(
        () => [

            {
                Header: 'Research Opportunities',
                columns: [
                    
                    {
                        Header: 'Eligible Trainee(s)',
                        accessor: 'projectTraineeNames',
                        Cell: ({ row }) => <div>{row.original.projectTraineeNames.join(', ')}</div>,
                        Filter: TraineeSelectColumnFilter,
                        sortType: "basic",
                        filter: 'includes'
                    },
                    {
                        Header: 'Type',
                        accessor: 'projectTypeName',
                        // Use our custom `fuzzyText` filter on this column
                        Filter: SelectColumnFilter,
                        filter: 'includes',
                        sortType: "basic",
                        // Use another two-stage aggregator here to
                        // first count the UNIQUE values from the rows
                        // being aggregated, then sum those counts if
                        // they are aggregated further
                        aggregate: ['sum', 'uniqueCount'],
                        Aggregated: ({ cell: { value } }) => `${value} Types`,
                    },
                    {
                        // accessor: item => <Button onClick={() => this.handleClick(item.id)} />
                        //<button onClick={open}>Open Dialog</button>
                        //<button onClick={() => {setIsPopOut(!isPopOut);}}>
                        Header: 'Project/Program Title',

                        // Use another two-stage aggregator here to
                        // first count the UNIQUE values from the rows
                        // being aggregated, then sum those counts if
                        // they are aggregated further
                        accessor: 'title',
                        filter: 'fuzzyText',
                        // Use another two-stage aggregator here to
                        // first count the UNIQUE values from the rows
                        // being aggregated, then sum those counts if
                        // they are aggregated further
                        aggregate: 'uniqueCount',
                        Aggregated: ({ value }) => `${value} Unique Names`,
                        Cell: ({ row }) =>
                            <a href="#" onClick={() =>
                                show(
                                    row.original.id,
                                    row.original.title,
                                    row.original.projectTypeName,
                                    row.original.mentorName,
                                    row.original.mentorEmail,
                                    row.original.adminContact,
                                    row.original.departmentName,
                                    row.original.statusName,
                                    (row.original.locationName == 'undefined' || row.original.locationName == '' || row.original.locationName == null) ? 'Not Available' : row.original.locationName,
                                    row.original.description,
                                    row.original.status,
                                    row.original.postDate,
                                    row.original.expiryDate
                                )}>
                                {row.original.title}
                            </a>
                    },
                    {
                        Header: 'Mentor Affiliation',
                        accessor: 'departmentName',
                        Filter: SelectColumnFilter,
                        sortType: "basic",
                        filter: 'includes',
                    },
                    {
                        Header: 'Location',
                        accessor: 'locationName',
                        Filter: SelectColumnFilter,
                        sortType: "basic",
                        filter: 'includes'

                    },

                    {
                        Header: 'Mentor',
                        accessor: 'mentorName',

                    },
                    {
                        Header: 'Start Date',
                        accessor: 'postDate',
                        Filter: EmptyFilter,
                        Cell: ({ row }) => <Moment format="MM/DD/YYYY">{row.original.postDate}</Moment>
                    },
                    {
                        Header: 'End Date',
                        accessor: 'expiryDate',
                        Filter: EmptyFilter,
                        Cell: ({ row }) => <Moment format="MM/DD/YYYY">{row.original.expiryDate}</Moment>
                    },
                    {
                        Header: () => (
                            <div
                                style={{
                                    width: 50,
                                    textAlign: "center"
                                }}>
                                Status
                            </div>
                        ),
                        accessor: 'statusName',
                        Filter: StatusSelectColumnFilter,
                        sortMethod: (a, b) => Number(a) - Number(b),
                        Cell: ({ row }) =>
                            <div title={row.original.statusName} style={{ textAlign: "center" }} className={row.original.status === 1 ? "activeColor" : (row.original.status === 2 ? "opportunityColor" : "expiredColor")}>
                                {row.original.statusName}
                                {/*{row.original.status === 1 ? <VerifiedIcon /> : (row.original.status === 2 ? <EventBusyIcon /> : <TimerOffIcon />)}*/}
                            </div>
                    },
                    {
                        id: 'Edit',
                        className: "adminControls",
                        Cell: ({ row }) =>
                            <div className="adminControls">
                                <div className="editButton" title="Edit Project">
                                    <EditIcon sx={{ "& :hover": { color: "Blue" } }} className="editIcon" tooltip="Edit Project" fontSize="medium"
                                    onClick={() =>
                                        showEdit(
                                            row.original.id,
                                            row.original.title,
                                            row.original.projectTypeName,
                                            row.original.projectTypeId,
                                            row.original.deptId,
                                            row.original.locationId,
                                            row.original.mentorName,
                                            row.original.mentorEmail,
                                            row.original.adminContact,
                                            row.original.departmentName,
                                            row.original.locationName,
                                            row.original.description,
                                            row.original.status,
                                            row.original.postDate,
                                            row.original.expiryDate,
                                            row.original.url,
                                            row.original.mentorPhone,
                                            row.original.mentorId,  
                                            row.original.isApproved,
                                            row.original.isEqualOpp,
                                            row.original.isRejected
                                            )} />
                                </div><div>&nbsp;&nbsp;</div>
                                <div className="deleteButton" title="Delete Project">
                                    <DeleteForeverIcon sx={{ "& :hover": { color: "Red" } }} className="deleteIcon" tooltip="Delete Project" fontSize="medium"
                                        onClick={() => window.confirm("Are you sure you wish to delete this item?") && handleDelete(row.original)}
                                    />
                                </div>
                              </div>
                    }
                ]
            }
        ],
        [handleDelete]
    )
    const [data, setData] = React.useState([]);
    const [error, setError] = useState(null);

    const fetchAndUpdate = useCallback(() => {
        console.log('Fetching data...');
        projectData()
            .then(res => {
                console.log('Data received:', res.data);
                setData(res.data);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
                setError(err.message || 'An error occurred while fetching data');
            });
    }, []);

    // fetch data, pass in `true` to only return entries that have been approved
    React.useEffect(() => {
        console.log('Component mounted, fetching initial data...');
        fetchAndUpdate()
    }, [fetchAndUpdate]);

    //const [data, setData] = React.useState(() => pdata)
    const [originalData] = React.useState(data)

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.
    const skipResetRef = React.useRef(false)

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        skipResetRef.current = true
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...row,
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
        skipResetRef.current = false
    }, [data])

    // Let's add a data resetter/randomizer to help
    // illustrate that flow...
    const resetData = () => {
        // Don't reset the page when we do this
        skipResetRef.current = true
        setData(originalData)
    }

    return (
        <div id="mainContent">
            <div id="researchAdminTable">
                <Styles>
                    <br />
                    <div className="instructions">
                        The following research opportunities are available for University of Pittsburgh medical students as well as UPMC medical residents and fellows.
                        Drop down menus at the top of each column can be used to sort entries.                    </div>
                    <br />

                    {/* <button onClick={resetData}>Reset Data</button> */}
                    <Table
                        columns={columns}
                        data={data}
                        updateMyData={updateMyData}
                        skipReset={skipResetRef.current}
                    />
                </Styles>
            </div>
            {isShown && (
                <ProjectDetails
                    idVal={idVal}
                    titleVal={titleVal}
                    typeVal={typeVal}
                    mentorNameVal={mentorNameVal}
                    mentorEmailVal={mentorEmailVal}
                    adminContactVal={adminContactVal}
                    deptVal={deptVal}
                    locationVal={locationVal}
                    statusNameVal={statusNameVal}
                    statusVal={statusVal}
                    descVal={descVal}
                    postDateVal={<Moment format="MM/DD/YYYY">{postDateVal}</Moment>}
                    expiryDateVal={<Moment format="MM/DD/YYYY">{expiryDateVal}</Moment>}
                    hide={hide}
                />
            )}

            {showEditForm && (
                <EditForm
                    idVal={idVal}
                    titleVal={titleVal}
                    typeVal={typeVal}
                    typeIdVal={typeIdVal}
                    deptIdVal={deptIdVal}
                    locationIdVal={locationIdVal}
                    mentorNameVal={mentorNameVal}
                    mentorEmailVal={mentorEmailVal}
                    adminContactVal={adminContactVal}
                    deptVal={deptVal}
                    locationVal={locationVal}
                    statusVal={statusVal}
                    descVal={descVal}
                    postDateVal={postDateVal}
                    expiryDateVal={expiryDateVal}
                    urlVal={urlVal}
                    phoneVal={phoneVal}
                    mentorIdVal={mentorIdVal}
                    isApprovedVal={isApprovedVal}
                    isEqualOppVal={isEqualOppVal}
                    isRejectedVal={isRejectedVal}
                    //hide={hideEditForm}
                    updateAndHide={hideEditForm}
                />
            )}
        </div>

    )
}

export default ResearchEntriesAdmin;
