import axios from "axios";

const projectData = async () => {
    console.log('projectData function called');
    const apiUri = "api/projects/GetAllProjects/";
    try {
        console.log('Sending request to:', apiUri);
        const result = await axios(apiUri, {
            params: {
                isDeleted: false
            }
        });
        console.log('Response received:', result);
        return result;
    } catch (error) {
        console.error('Error in projectData:', error);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        throw error; // Re-throw the error to be caught in the component
    }
};

export default projectData;